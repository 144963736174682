import Vue from 'vue';

import svg_icons from './common/svg_icons.vue';

// SASS/CSS
import '../../css/sass/public.scss';

// images
import '../../images/icons-public.svg';

// disable the warning about dev/prod
Vue.config.productionTip = false;

new Vue({
    el: '#app',

    components: {
        'svg-icons': svg_icons,
    },

    data: {
    	show: false,

    	service1: false,
        service2: false,
        service3: false,
        service4: false,
        service5: false,
        service6: false,
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.windowResize);
        });
    },

     methods: {

     	toggle(e) {
           this.show = !this.show
           e.preventDefault();
        },


        serviceToggle1(e) {
           this.service1 = !this.service1
           this.service2 = false
           this.service3 = false
           this.service4 = false
           this.service5 = false
           this.service6 = false
           e.preventDefault();
        },

        serviceToggle2(e) {
           this.service2 = !this.service2
           this.service1 = false
           this.service3 = false
           this.service4 = false
           this.service5 = false
           this.service6 = false

           e.preventDefault();
        },
        serviceToggle3(e) {
           this.service3 = !this.service3
           this.service1 = false
           this.service2 = false
           this.service4 = false
           this.service5 = false
           this.service6 = false
           e.preventDefault();
        },
        serviceToggle4(e) {
           this.service4 = !this.service4
           this.service1 = false
           this.service2 = false
           this.service3 = false
           this.service5 = false
           this.service6 = false
           e.preventDefault();
        },
        serviceToggle5(e) {
           this.service5 = !this.service5
           this.service1 = false
           this.service2 = false
           this.service3 = false
           this.service4 = false
           this.service6 = false
           e.preventDefault();
        },
        serviceToggle6(e) {
           this.service6 = !this.service6
           this.service1 = false
           this.service2 = false
           this.service4 = false
           this.service5 = false
           e.preventDefault();
        },
    },
});
